import { defineStore } from "pinia";
import type { ResultEntity } from '~/types/resultEntity';

interface State {
  result: ResultEntity | null,
  resultHistory: ResultEntity[],
}

export const useResultStore = defineStore('result', {
  state: (): State => ({
    result: null,
    resultHistory: []
  }),
  actions: {
    async getResultHistory () {
      const api = useApi()
      const historyEither = await api.resultApi.getHistory();

      historyEither.mapRight((history) => {
        this.setResultHistory(history)
      })
    },
    setResultHistory (resultHistory: ResultEntity[]) {
      this.resultHistory = resultHistory.map((r: ResultEntity) => {
        let completedAt = undefined;
        if (r.completedAt) {
          completedAt = new Date(r.completedAt);
        }
        return { ...r, createdAt: new Date(r.createdAt), updatedAt: new Date(r.updatedAt), completedAt }
      })
    },
    setResult(result: ResultEntity) {
      this.result = result;
    },
  },
});
